import {ApplicationRef, Directive, ElementRef, EventEmitter, forwardRef, Injectable, Input, OnInit, Output, Provider} from "@angular/core";
import {ISelectOptions, Select} from "@widgets/Select";
import {ExternalSearchResult, ISelectListItem, SelectionChangedEvent} from "@widgets/SelectList";
import {ContentComponent} from "@utils/Angular/ContentComponent";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms"
import {JSONData} from "@api";
import {arrayify} from "@utils/Utils";

const NUMBER_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectDirective),
  multi: true
};

export type NgSelectionChangedEvent<T extends JSONData = JSONData> = {
  addedItems: ISelectListItem<T>[],
  removedItems: ISelectListItem<T>[],
  selection: ISelectListItem<T>[]
};

@Directive({
             selector: 'select[ngSelect]',
             providers: [NUMBER_VALUE_ACCESSOR],
             exportAs: "select",
             host: {
               "(change)": "onChange()"
             }
           })
@Injectable()
export class SelectDirective extends ContentComponent implements ControlValueAccessor, OnInit {
  private _disabled: boolean;
  @Input()
  set disabled(value:boolean) {
    this.setDisabledState(value)
  }
  @Input()
  settings: Partial<ISelectOptions>;
  @Input()
  searchFunction: Func1<string, Promise<ExternalSearchResult>>;
  select: Select;
  @Output()
  selectionChanged = new EventEmitter<NgSelectionChangedEvent>();
  @Output()
  closed = new EventEmitter<NgSelectionChangedEvent>();
  @Output()
  initialized = new EventEmitter<Select>();
  private _isWritingValue = false;
  private _onChange?: () => void;
  private _onTouched?: Action1<string[] | string>;

  constructor(element: ElementRef, app: ApplicationRef) {
    super(element, app);
    if (!this.element.nextElementSibling || !this.element.nextElementSibling.classList.contains("fake-select")) {
      $(`<div class="fake-select custom-select" tabindex="0"></div>`)
        .insertAfter(this.element);
    }
    this.element.style.display = "none";
  }

  private _items: Partial<JSONData>[];

  @Input()
  set items(value: Partial<JSONData>[]) {
    this._items = value;
    if (this.select)
      this.select.processJsonData(value);
  }

  writeValue(obj: string | string[]): void {
    if (!this.select)
      return;
    this._isWritingValue = true;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const selectedValues = arrayify(obj);
    if (this._valueHasNotChanged(selectedValues)) {
      this._isWritingValue = false;
      return;
    }
    this.select.unselectItems(true, true);
    for (const item of this.select.getItems()) {
      if (selectedValues.contains(item.value)) {
        this.select.selectItems(item, true, true);
        selectedValues.remove(item.value);
      }
    }
    if (selectedValues.any())
      this.select.processJsonData(selectedValues.map(i => {
        return {value: i, label: i, selected: true}
      }))
    this._isWritingValue = false;
  }

  registerOnChange(fn: Action1<string[] | string>): void {
    this._onChange = () => {
      if (this._isWritingValue)
        return;
      if (this.select.options.multiple)
        fn(this.select.getSelectedItems().map(i => i.value));
      else if (this.select.getSelectedItems().length)
        fn(this.select.getSelectedItems()[0].value);
      else
        fn(null);
    };
  }

  registerOnTouched(fn: Action1<string[] | string>): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
    if (isDisabled)
      this.select.disable(true);
    else
      this.select.enable();
  }

  ngOnInit(): void {
    const $select = $(this.element);
    if ($select.data("select"))
      this.select = $select.data("select");
    else {
      const options = this.settings || $select.getJsonFromAttribute<ISelectOptions>("data-settings") || {};
      if (this.searchFunction)
        options.externalSearchHandler = this.searchFunction;
      this.select =
        new Select($select[0] as HTMLSelectElement, Object.assign({}, options, {onInitialized: null}));
    }
    if (this._items)
      this.select.processJsonData(this._items);
    this.select.onSelectionChanged.on(e => {
      this._selectionChanged(e)
    });
    if (this._disabled)
      this.select.disable(true)
  }

  onInitialized(): void {
    if (!this.select.$fakeSelect[0].contains(this.select.selectElement))
      this.select.$fakeSelect.insertAfter(this.select.selectElement);
    if (this.settings?.onInitialized)
      this.settings.onInitialized(this.select);
    this.initialized.emit(this.select);
  }

  onChange(): void {
    if (this._onChange)
      this._onChange();
  }

  ngOnDestroy(): void {
    this.select?.close();
    this.select.destroyUi();
    super.ngOnDestroy();
  }

  private _selectionChanged(e: SelectionChangedEvent<ISelectListItem, Select>) {
    const ngEvent: NgSelectionChangedEvent = {
      addedItems: e.addedItems,
      removedItems: e.removedItems,
      selection: this.select.getSelectedItems()
    };
    this.selectionChanged.emit(ngEvent);
  }

  private _valueHasNotChanged(newSelectedValues: string[]) {
    const currentSelectedValues = this.select.getSelectedItems()
    for (const currentSelectedValue of currentSelectedValues) {
      if (!newSelectedValues.contains(currentSelectedValue.value))
        return false;
    }
    return currentSelectedValues.length === newSelectedValues.filter(v => !!v).distinct().length;
  }
}

export interface ISelectComponentItem {
  content: ContainerType;
  value?: FunctionOrValue<any>;
  disabled?: boolean;
  selected?: boolean;
}
