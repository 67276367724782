import {
  BookingViewData,
  ChangePasswordViewData, ContainerDetailViewData,
  EntityEditViewData,
  EntityViewData,
  ForgotPasswordViewData,
  LoginViewData,
  OrderDetailViewData,
  PreLoginViewData,
  RequestAccessViewData,
  ShipmentDetailViewData,
  ShipmentListViewData,
  TemplateViewData,
  UserDetailViewData,
  UserEditViewData,
  UserViewData,
  ViewData
} from "@view-data";
import {ActivatedRouteSnapshot, ParamMap} from "@angular/router";
import {UnwrapFunction} from "@utils/Utils";
import {JobType} from "@api";

export function parseRouteSnapshotToViewData(snapshot: ActivatedRouteSnapshot): ViewData {
  const routeSnapshot = getDeepestRouteSnapshot(snapshot);
  const viewData: ViewData = UnwrapFunction(routeSnapshot.data.viewData) as ViewData;
  if (!viewData)
    return null;
  const queryParams = routeSnapshot.queryParamMap;
  if (viewData instanceof BookingViewData || viewData instanceof TemplateViewData)
    return handleBookingRouteData(viewData, queryParams);
  if (viewData instanceof PreLoginViewData)
    return handlePreLoginRouteData(viewData, queryParams);
  if (viewData instanceof EntityViewData)
    return handleEntityRouteData(viewData, queryParams);
  if (viewData instanceof OrderDetailViewData)
    return handleOrderDetailRouteData(queryParams);
  if (viewData instanceof ShipmentDetailViewData)
    return handleShipmentDetailRouteData(queryParams);
  if (viewData instanceof ContainerDetailViewData)
    return handleContainerDetailRouteData(queryParams);
  if (viewData instanceof ShipmentListViewData)
    return handleShipmentListRouteData(queryParams);
  if (viewData instanceof UserViewData)
    return handleUserRouteData(viewData, queryParams);
  return viewData;
}

function handleBookingRouteData(viewData: BookingViewData | TemplateViewData, queryParams: ParamMap) {
  const templateId = queryParams.get("templateId") || viewData.templateId;
  if (viewData instanceof BookingViewData)
    return new BookingViewData(viewData.step, templateId)
  else {
    return new TemplateViewData(viewData.step, templateId)
  }
}

function handleUserRouteData(viewData: UserViewData, queryParams: ParamMap) {
  const userId = queryParams.get("ID");
  if (viewData instanceof UserEditViewData)
    return new UserEditViewData(userId);
  else if (viewData instanceof UserDetailViewData)
    return new UserDetailViewData(userId);
  else
    return viewData;
}

function handleEntityRouteData(viewData: EntityViewData, queryParams: ParamMap) {
  const entityId = queryParams.get("id");
  if (viewData instanceof EntityEditViewData)
    return new EntityEditViewData(entityId);
  else
    return viewData;
}

function handleShipmentDetailRouteData(queryParams: ParamMap) {
  const shipmentId = queryParams.get("JobID");
  const shipmentTypeString = queryParams.get("ObjectType");
  const shipmentType = _parseShipmentType(shipmentTypeString);
  return new ShipmentDetailViewData(shipmentId, shipmentType);
}

function handleContainerDetailRouteData(queryParams: ParamMap) {
  const containerId = queryParams.get("ContainerID");
  return new ContainerDetailViewData(containerId);
}

function handleOrderDetailRouteData(queryParams: ParamMap) {
  const orderId = queryParams.get("pk");
  return new OrderDetailViewData(orderId);
}

function handlePreLoginRouteData(viewData: PreLoginViewData, queryParams: ParamMap) {
  if (viewData instanceof LoginViewData) {
    const unlocked = queryParams.has("unlocked");
    const returnUrl = queryParams.get("returnUrl");
    if (returnUrl)
      return new LoginViewData(returnUrl);
    else
      return new LoginViewData(unlocked);
  } else if (viewData instanceof ForgotPasswordViewData) {
    const mailAddress = queryParams.get("mailAddress");
    return new ForgotPasswordViewData(mailAddress);
  } else if (viewData instanceof ChangePasswordViewData) {
    const requestId = queryParams.get("requestID") || queryParams.get("requsetID");
    return new ChangePasswordViewData(requestId);
  } else if (viewData instanceof RequestAccessViewData) {
    return new RequestAccessViewData();
  }
}

function getDeepestRouteSnapshot(routeSnapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  const isDeepestSnapshot = !routeSnapshot.firstChild;
  return isDeepestSnapshot
         ? routeSnapshot
         : getDeepestRouteSnapshot(routeSnapshot.firstChild);
}

function handleShipmentListRouteData(queryParams: ParamMap) {
  const redoSearch = queryParams.get("search") === "true";
  const query = queryParams.get("q");
  let autoOpenSingleItem: boolean | null = null
  if (queryParams.get("autoopensingleitem"))
    autoOpenSingleItem = queryParams.get("autoopensingleitem").toLowerCase() === "true";
  return new ShipmentListViewData({redoSearch, query, autoOpenSingleItem});
}

function _parseShipmentType(shipmentTypeString: string): JobType {
  shipmentTypeString = (shipmentTypeString || "").toUpperCase();
  if (shipmentTypeString === "CD")
    return JobType.CustomsDeclaration;
  if (shipmentTypeString === "BKG")
    return JobType.Booking
  return JobType.Shipment
}
