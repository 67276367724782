import {OrderViewData} from "./order-view-data";
import {TitleBuilder} from "@utils/builders/title-builder";
import {UrlBuilder} from "@utils/builders/url-builder";

export class OrderListViewData extends OrderViewData {
  get pageTitle(): string {
    return TitleBuilder.order.list;
  }

  get url(): string {
    return UrlBuilder.order.list;
  }

  get bodyClasses(): string[] {
    return ["page-order-list"];
  }

}
