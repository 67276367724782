import {Injectable} from "@angular/core";
import {EntityInfo, EntitySetInfo, Order, OrderSearch} from "@api";
import {AccountStore, OrderStore, OrderStoreState, UserRightsStore} from "@stores";
import {OrderDetailViewData, OrderListViewData} from "@view-data";
import {debounceTime, distinctUntilKeyChanged, filter, skip} from "rxjs/operators";
import {hasValue, TypeFilter} from "@utils/rxjs-extensions";
import {OrderApi} from "../api/Apis";
import {OrderSearchUiStore} from "@app/page-components/order/order-list/order-search-bar/order-search-ui-store.service";
import {RoutingService} from "@app/services/routing.service";

@Injectable({
              providedIn: 'root'
            })
export class OrderService {
  readonly orderStore: OrderStore;
  private readonly _orderApi: OrderApi;
  private readonly _routingService: RoutingService;

  constructor(orderApi: OrderApi,
              orderStore: OrderStore,
              userRightsStore: UserRightsStore,
              accountStore: AccountStore,
              orderSearchUiStore: OrderSearchUiStore,
              routingService: RoutingService) {
    this._routingService = routingService;
    this.orderStore = orderStore;
    this._orderApi = orderApi

    accountStore
      .subscribe("currentOrderSearch",
                 s => {
                   if (s)
                     orderSearchUiStore.update(s);
                   else
                     orderSearchUiStore.reset()
                   orderStore.update("currentSearch", s || {});
                 });

    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(TypeFilter(OrderDetailViewData)))
      .subscribe((v: OrderDetailViewData) => {
        void this._loadOrder(v)
      })
    routingService
      .routingStore
      .observe("currentViewData")
      .pipe(filter(TypeFilter(OrderListViewData)))
      .subscribe(() => {
        void this._loadOrderList()
      })
    orderStore
      .observe("currentSearch")
      .pipe(
        skip(1),
        filter(() => userRightsStore.get("isAuthenticated")),
        debounceTime(100)
      )
      .subscribe(s => {
        if (routingService.getCurrentViewData() instanceof OrderListViewData)
          void this._search(s)
        else
          this.orderStore.update("orderList", null)
      });
    userRightsStore
      .observe("isAuthenticated")
      .pipe(filter(u => u === false))
      .subscribe(() => this.orderStore.reset());
    accountStore
      .observe("currentEntitySet")
      .pipe(
        filter(hasValue),
        distinctUntilKeyChanged("id")
      )
      .subscribe(s => this._entitySetChanged(s))
  }

  hasUserAccess(id: string, entitySetId: string): Promise<boolean>;
  hasUserAccess(id: string): Promise<boolean>;
  async hasUserAccess(id: string, entitySetId?: string): Promise<boolean> {
    const currentOrderList = this.orderStore.get("orderList");
    if (currentOrderList?.any(s => s.id === id))
      return true;
    if (entitySetId)
      return await this._orderApi.hasUserAccessForEntitySet(id, entitySetId).toPromise()
    else
      return await this._orderApi.hasUserAccess(id).toPromise()
  }

  get(id: string): Promise<Order | null> {
    return this._orderApi.orderDetail(id).toPromise();
  }

  async loadMore(): Promise<Order[] | null> {
    try {
      this.orderStore.update({isLoadingMore: true})
      const result = await this._orderApi.loadMoreOrders().toPromise();
      this.orderStore.update((state: Partial<OrderStoreState>) => {
        return {
          orderList: (state.orderList || []).concat(result.orders || []),
          moreOrdersAvailable: result.moreOrdersAvailable
        }
      })
      return result.orders;
    } finally {
      this.orderStore.update({isLoadingMore: false});
    }
  }

  startSearch(searchObject: Partial<OrderSearch>): void {
    this.orderStore.update("currentSearch", searchObject);
    void this._search(searchObject)
  }

  private async _search({
                          endDate,
                          reference,
                          referenceType,
                          startDate,
                          status,
                          transportMode
                        }: OrderSearch): Promise<Order[] | null> {
    try {
      this.orderStore.update({isSearching: true});
      const result = await this._orderApi.searchOrders(status,
                                                       transportMode,
                                                       startDate,
                                                       endDate,
                                                       referenceType,
                                                       reference)
                               .toPromise();
      this.orderStore.update({
                               orderList: result.orders,
                               moreOrdersAvailable: result.moreOrdersAvailable
                             });
      return result.orders;
    } finally {
      this.orderStore.update({isSearching: false});
    }
  }

  private async _loadOrder(viewData: OrderDetailViewData): Promise<void> {
    const orderFromStore = this._getOrderFromStore(viewData);
    if (orderFromStore)
      this._updateCurrentOrderInStore(orderFromStore);
    else {
      const order = await this.get(viewData.orderId);
      this._updateCurrentOrderInStore(order);
    }
  }

  private _updateCurrentOrderInStore(orderFromStore: Order): void {
    this.orderStore.update({currentOrder: orderFromStore});
  }

  private _getOrderFromStore(viewData: OrderDetailViewData): Order {
    const currentOrders = this.orderStore.get("orderList") ?? [];
    return currentOrders.firstOrDefault((o: Order) => o.id === viewData.orderId);
  }

  private async _loadOrderList() {
    if (this.orderStore.hasValue("orderList"))
      return;
    const search = this.orderStore.get("currentSearch");
    await this._search(search)
  }

  private async _entitySetChanged(entitySet: EntitySetInfo) {
    if (!this._hasOrderRights(entitySet)) {
      this.orderStore.reset();
      return;
    }
    const currentViewData = this._routingService.routingStore.get("currentViewData");
    if (currentViewData instanceof OrderListViewData) {
      this.orderStore.update("orderList", [])
      await this._search(this.orderStore.get("currentSearch"));
    } else {
      let currentOrder: Order | null = null;
      this.orderStore.update("orderList", null)
      if (currentViewData instanceof OrderDetailViewData) {
        const id = currentViewData.orderId;
        const userCanStillViewOrder = await this.hasUserAccess(id, entitySet.id);
        if (userCanStillViewOrder)
          currentOrder = await this._orderApi.orderDetail(id).toPromise();
        else
          this._routingService.navigateTo(new OrderListViewData())
      }
      this.orderStore.update({currentOrder})
    }
  }

  private _hasOrderRights(entity: EntityInfo): boolean {
    return entity.hasOrderRights ||
           entity.subSites && entity.subSites.any(s => this._hasOrderRights(s));
  }

  private _openSingleOrderAutomatically() {
    const {
      endDate,
      reference,
      startDate,
      status,
      transportMode
    } = this.orderStore.get("currentSearch")
    const arrays: unknown[][] = [status,transportMode];
    if (arrays.any(a => a?.any() === true))
      return false;
    const values =  [
      endDate,
      reference,
      startDate
    ]
    return values.any(v => !!v);
  }
}
